@use 'styles/base/mixins';
@use 'styles/base/variables';

.black {
  color: variables.$gb_white;
}

.dateList {
  width: 100%;
  border-top: 2px solid variables.$gb_grey_150;
  margin: 1.25rem 0 0.75rem 0;

  @include mixins.screen-sm {
    margin: 1.5rem 0 1rem 0;
  }

  td {
    border-left: none;
    border-bottom-width: 2px;
    padding: 0.5rem 0;
    font-size: 0.875rem;

    @include mixins.screen-sm {
      padding: 0.5rem;
    }

    &:first-child span {
      @include mixins.line-clamp(2);
    }

    &:last-child {
      text-align: right;
      white-space: nowrap;
    }
  }
}

.translationWrapper {
  margin: 1.25rem 0 0.75rem 0;
}
